<template>
  <aside>
    <header>
      <h2>Add custom catalog item</h2>
      <button class="close" @click="close">
        <span class="sr-only">Close modal window</span>
      </button>
    </header>

    <main>
      <div class="setting-row">
        <div class="title">
          <h4>Catalog Source (optional)</h4>
          <p>Select which integration to import this item from.</p>
        </div>
        <div class="fields">
          <select
            name="catalog-type"
            v-model="item.integration_id"
            class="form-control"
            id="catalog-type"
          >
            <option :value="''">Select an integration...</option>
            <option
              v-for="(integration, index) in catalog_integration"
              :key="`int_cat_opt_${index}`"
              :value="integration.id"
              >{{ integration.integration_type }}</option
            >
          </select>
        </div>
      </div>

      <div class="setting-row" v-if="item.integration_id !== ''">
        <div class="title">
          <h4>External ID</h4>
          <p>Link this item to a product from your catalog integration.</p>
        </div>
        <div class="fields">
          <input
            type="text"
            class="form-control"
            v-model="item.external_id"
            placeholder="(optional)"
          />
        </div>
      </div>

      <div class="setting-row">
        <div class="title">
          <h4>Title</h4>
        </div>
        <div class="fields">
          <input
            type="text"
            v-model="$v.item.name.$model"
            class="form-control"
          />
        </div>
      </div>

      <div class="setting-row">
        <div class="title">
          <h4>Description</h4>
        </div>
        <div class="fields">
          <textarea
            rows="3"
            v-model="item.description"
            class="form-control"
          ></textarea>
        </div>
      </div>

      <div class="setting-row">
        <div class="title">
          <h4>Point Cost</h4>
        </div>
        <div class="fields">
          <div class="input-group">
            <input
              type="text"
              v-model.number="$v.item.points.$model"
              class="form-control"
            />
            <div class="input-group-append">
              <label class="input-group-text">points</label>
            </div>
          </div>
        </div>
      </div>

      <div class="setting-row">
        <div class="title">
          <h4>Redemption instructions</h4>
        </div>
        <div class="fields">
          <textarea
            rows="3"
            v-model="item.instructions"
            class="form-control"
          ></textarea>
        </div>
      </div>

      <div class="setting-row">
        <div class="title">
          <h4>Require shipping address?</h4>
        </div>
        <div class="fields">
          <ht-switch v-model="item.use_shipping" />
        </div>
      </div>

      <div
        class="setting-row product-variations"
        v-if="item.integration_id === ''"
      >
        <div class="title">
          <h4>Product Variations</h4>
        </div>
        <div class="fields">
          <ul>
            <li
              v-for="(variant, index) in $v.variations.$each.$iter"
              :key="`pv_${index}`"
            >
              <button
                @click.prevent="$v.variations.$model.splice(index, 1)"
                class="btn btn-danger btn-circle btn-xs"
              >
                <i class="fas fa-trash"></i>
              </button>
              <div class="form-group">
                <label :for="`variation_${index}`">Variation label</label>
                <input
                  class="form-control"
                  type="text"
                  :id="`variation_${index}`"
                  v-model="variant.variation.$model"
                />
              </div>
            </li>
          </ul>
          <button
            class="btn btn-xs btn-circle btn-dark"
            @click.prevent="variations.push({ variation: '' })"
          >
            <i class="fas fa-plus"></i>
          </button>
        </div>
      </div>

      <div class="setting-row">
        <div class="title">
          <h4>Country Availability</h4>
        </div>
        <div class="fields">
          <v-select
            :options="country_options"
            :clearable="true"
            :multiple="true"
            class="country-selector"
            placeholder="Select countries..."
            v-model="$v.item.countries.$model"
            :reduce="(country) => country.value"
          />
          <p class="help-text">
            Leave blank to make available in all countries.
          </p>
        </div>
      </div>

      <div class="setting-row" v-if="integration_catalog_type !== 'PRINTFUL'">
        <div class="title">
          <h4>Images</h4>
        </div>
        <div class="fields">
          <div class="gallery-wrap">
            <ul v-if="upload_images.length > 0">
              <li
                v-for="(image, index) in upload_images"
                :key="`upload_image_${image.id}`"
              >
                <div
                  class="thumbnail"
                  :style="{ 'background-image': `url('${image.preview}')` }"
                ></div>
                <a href="#" @click.prevent="removeImage(index)"
                  ><i class="far fa-times"></i> Remove</a
                >
              </li>
            </ul>
            <button
              class="btn btn-xs btn-secondary"
              @click.prevent="chooseFile"
            >
              <i class="fas fa-plus"></i> Add image
            </button>
            <input
              type="file"
              ref="file_uploader"
              accept="image/*"
              @change="handleFile"
            />
          </div>
        </div>
      </div>
    </main>

    <footer>
      <button
        class="btn btn-sm btn-green"
        :class="{ loading: loading }"
        @click.prevent="addItem"
      >
        Add item
      </button>
    </footer>
  </aside>
</template>

<script>
import { required, minValue, minLength } from 'vuelidate/lib/validators';

export default {
  computed: {
    loading() {
      return this.$store.state.loading;
    },
    catalog_integration() {
      return this.$store.state.integrations.filter(
        (i) => i.data_type === 'REWARD_CATALOG'
      );
    },
    integration_catalog_type() {
      if (!this.item.integration_id) return '';
      return this.catalog_integration.find(
        (i) => i.id === this.item.integration_id
      ).integration_type;
    },
    country_options() {
      return Object.keys(this.$geoData.all_countries).reduce((res, item) => {
        res.push({
          label: this.$geoData.all_countries[item],
          value: item,
        });
        return res;
      }, []);
    },
  },
  data() {
    return {
      integration_catalog: '',
      item: {
        name: '',
        description: '',
        points: 0,
        integration_id: '',
        external_id: '',
        use_shipping: true,
        instructions: '',
      },
      variations: [],
      upload_images: [],
    };
  },
  validations: {
    item: {
      name: {
        required,
      },
      points: {
        required,
      },
      countries: {
        minLength: minLength(0),
      },
    },
    variations: {
      $each: {
        variation: {
          required,
        },
      },
    },
  },
  methods: {
    removeImage(index) {
      this.upload_images.splice(index, 1);
    },
    close() {
      this.$emit('done');
    },
    async addItem() {
      this.$v.$touch();
      if (this.$v.$invalid) return;

      this.$store.commit('loading', true);

      const resp = await this.$api.Rewards.add_custom_item({
        ...this.item,
        variations: this.variations,
      });

      //try to upload images
      const fileData = new FormData();
      this.upload_images.forEach(({ file }, i) => {
        fileData.append(`images`, file);
      });

      await this.$api.Rewards.upload_custom_item_images(resp.id, fileData);

      this.$store.commit('loading', false);
      this.$emit('done', 'ADDED_ITEM');
    },
    chooseFile() {
      this.$refs.file_uploader.value = null;
      this.$refs.file_uploader.click();
    },
    handleFile() {
      const file = this.$refs.file_uploader.files[0];

      const fr = new FileReader();
      fr.onload = () => {
        this.upload_images.push({
          id: this.$rand(),
          preview: fr.result,
          file,
        });
      };
      fr.readAsDataURL(file);
    },
  },
};
</script>

<style lang="scss" scoped>
.product-variations {
  ul {
    display: block;
    padding: 0;
    margin: 0;

    > li {
      padding: 0 0 0 35px;
      margin: 0 -5px 0 -40px;
      display: flex;
      position: relative;

      > button {
        position: absolute;
        left: 0;
        top: 37px;
        display: none;
      }

      > div {
        flex: 1;
        padding: 0 5px;

        > label {
          font-size: 12px;
          font-weight: 700;
        }
      }

      &:hover {
        > button {
          display: block;
        }
      }
    }
  }
}

.gallery-wrap {
  background: $light;
  border-radius: 0.25rem;
  min-height: 50px;
  padding: 15px;

  ul {
    display: flex;
    padding: 0;
    margin: -5px -5px 15px -5px;
    > li {
      display: block;
      margin: 5px;
      flex: 0 0 25%;
      border-radius: 0.25rem;
      background-color: #fff;
      position: relative;
      overflow: hidden;

      > .thumbnail {
        padding: 0 0 100%;
        background-size: contain;
        background-position: center center;
        background-repeat: no-repeat;
      }

      > a {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 2;
        background: rgba($body-color, 0.5);
        color: #fff;
        text-align: center;
        line-height: 12px;
        font-size: 12px;
        font-weight: 700;
        padding: 3px 2px;
        display: none;
        text-decoration: none;

        &:hover {
          background: rgba($body-color, 0.8);
        }
      }

      &:hover {
        > a {
          display: block;
        }
      }
    }
  }

  input {
    width: 0;
    height: 0;
    overflow: hidden;
    appearance: none;
    text-indent: -999px;
    opacity: 0;
    position: absolute;
  }
}
</style>
